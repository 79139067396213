<template>
  <div class="p">
    <div class="middle">
      <el-table
          ref="table"
          v-loading="searchLoading"
          :data="pageProps.records"
          element-loading-text="正在查询"
          fit
          stripe
          highlight-current-row
        >
    
        <el-table-column prop="title" label="众包名" >
        </el-table-column>

        <el-table-column prop="mode" label="类型" >
          <template slot-scope="{row}">
              <span v-if="row.mode==1">招标</span>
              <span v-if="row.mode==2">帮忙</span>
          </template>
        </el-table-column>
      
        <el-table-column prop="start_at" label="开始时间" >
        </el-table-column>

        <el-table-column prop="end_at" label="结束时间" >
        </el-table-column>


        <el-table-column prop="wages" label="员工工资" >
        </el-table-column>
        


        <el-table-column prop="status" label="审核状态" >
          <template slot-scope="{row}">
            <span v-if="row.status==0">审核中</span>
            <span v-if="row.status==1">招标中</span>
              <span v-if="row.status==2">已取消</span>
              <span v-if="row.status==3">已拒绝</span>
              <span v-if="row.status==4">已驳回</span>
            
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" align="center" class-name="small-padding " >
            <template slot-scope="{row}">
            
              <el-button  type="primary" size="mini" @click="detail(row.crowd_id)">
                详情
              </el-button>
            </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align:center;padding:20px">
      <!--分页-->
      <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageProps.total"
          :page-size="pageProps.pageSize"
          :page-sizes="[5, 10, 50, 100]"
          :current-page="pageProps.pageNum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
    </div>      
    <info-dialog ref="infoDialogRef" @refresh="fetchData"></info-dialog>

  </div>
</template>

<script>
import { crowdList } from "@/api/project/index";
import infoDialog from './dialog/zhongbao-dialog.vue';

export default {
  components: {
    infoDialog
  },
  data() {
    return {
      searchLoading: false,
        pageProps: {
          records:[],
          total: 0,
          pageSize: 10,
          pageNum: 1
        },

        searchFormData:{
          name:"",
          page:0,
          limit:0
        },
    };
  },
  mounted() {
      this.fetchData();
    },
  methods: {

    fetchData(){
        var that = this;
        that.searchFormData.page=that.pageProps.pageNum;
        that.searchFormData.limit=that.pageProps.pageSize;

        crowdList(that.searchFormData).then((response) => {
          console.log(response);
          if(response.code==200){
            that.pageProps.records = response.data.data;
            that.pageProps.total = response.data.total;
          }else{
            that.$message.error(response.msg?response.msg:"请求错误");
          }
         
        });
      },

      detail(crowd_id){

      this.$refs.infoDialogRef.init(crowd_id);

      },

       // 当前页变更
      handleCurrentChange(num) {
        this.pageProps.pageNum = num
        this.fetchData()
      },


      // 每页显示页面数变更
      handleSizeChange(size) {
        this.pageProps.pageSize = size
        this.fetchData()
      },
      back(){
      this.$router.go(-1);
    },

      formatDateTime(value) { // 时间戳转换日期格式方法
        if (value == null) {
          return ''
        } else {
          const date = new Date(value)
          const y = date.getFullYear() // 年
          let MM = date.getMonth() + 1 // 月
          MM = MM < 10 ? ('0' + MM) : MM
          let d = date.getDate() // 日
          d = d < 10 ? ('0' + d) : d
          let h = date.getHours() // 时
          h = h < 10 ? ('0' + h) : h
          let m = date.getMinutes()// 分
          m = m < 10 ? ('0' + m) : m
          let s = date.getSeconds()// 秒
          s = s < 10 ? ('0' + s) : s
          return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
          }
      }
  },
};
</script>

<style>

</style>
